(function() {
    'use strict';

    angular.module('common.routes.helper', [])
        .service('routeHelper', routeHelper);

    routeHelper.$inject = [];

    function routeHelper() {

        angular.extend(this, {
            url: url
        });

        function url(routeConst, isHtml5Mode) {
            return (!isHtml5Mode ? '#': '') + routeConst.fullUrl;
        }
    }
})();
